<template>
  <div class="macfinder">
    <h1>{{ $t('system.macfinder') }}</h1>
    <b-alert variant="danger" :show="true" v-if="error_text !== undefined" dismissible>
      {{ error_text }}
    </b-alert>
    <p>{{ $t('views.tools.macfinder.description') }}</p>
    <b-alert variant="info" :show="true">
      <b>{{ $t('views.tools.macfinder.limits.limitations') }}</b>
      <ul>
        <li>{{ $t('views.tools.macfinder.limits.no-wlan') }}</li>
        <li>{{ $t('views.tools.macfinder.limits.untagged') }}</li>
        <li>{{ $t('views.tools.macfinder.limits.only-scc-switches') }}</li>
        <li>{{ $t('views.tools.macfinder.limits.no-vpn2vlan') }}</li>
        <li>{{ $t('views.tools.macfinder.limits.active-clients') }}</li>
      </ul>
    </b-alert>
    <b-form @submit="createJob">
      <b-form-group :invalid-feedback="$t('views.tools.macfinder.invalid_bcd')" :state="search_bcd !== null"
                    :label="$t('system.broadcast_domain')">
        <Typeahead
            :data="bcds"
            v-model="bcd_query"
            :serializer="serializeBCD"
            :placeholder="$tc('system.bcd', 1)"
            @select="search_bcd = $event"
            :loading="searching_bcds"
        />
      </b-form-group>
      <b-form-group :label="$t('system.mac_address')" :description="$t('views.tools.macfinder.any_format_possible')">
        <b-input required v-model="search_mac" :placeholder="$t('system.mac_address')"></b-input>
      </b-form-group>
      <b-button type="submit" class="float-right" variant="outline-success">
        <netvs-icon icon="search"></netvs-icon>
        {{ $t('system.search') }}
      </b-button>
    </b-form>
    <div class="clearfix"></div>
    <hr>
    <h2>{{ $t('views.tools.macfinder.results') }}</h2>
    <Loading :data="[jobs]">
      <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items="render_jobs" :busy="jobs === null"
               :fields="res_fields">
        <template v-slot:cell(mac)="data">
          <code>{{ data.item.mac }}</code> ({{ data.item.vendor }})
        </template>
        <template v-slot:cell(status)="data">
          <template v-if="data.item.finished">
            <template v-if="data.item.status.result.length == 0">
              <i>{{ $t('views.tools.macfinder.no_devices_found') }}</i>
            </template>
            <template v-else>
              <ul>
                <li v-for="res in data.item.status.result" v-bind:key="res.host + res.port">
                  {{ $t('views.tools.macfinder.switch') }} <code>{{ res.host }}</code>,
                  {{ $t('views.tools.macfinder.port') }} <code>{{ res.port }}</code>
                  <template v-if="res._resolved.length > 0"> -> {{ $t('views.tools.macfinder.building') }}
                    {{ res._resolved[0].mdl_bldg }}, {{ $t('views.tools.macfinder.room') }}
                    {{ res._resolved[0].mdl_room }}, {{ $t('views.tools.macfinder.module') }}
                    {{ res._resolved[0].mdl_fq_name }} (<span
                        :title="res._resolved[0].mdl_type">{{ res._resolved[0].type }}</span>),
                    Port {{ res._resolved[0].name }}
                  </template>
                </li>
              </ul>
            </template>
          </template>
          <template v-else-if="data.item.failed">
            {{ $t('views.tools.macfinder.search_failed') }}
          </template>
          <template v-else>
            {{ $t('views.tools.macfinder.search_in_progress') }}
            <b-spinner small></b-spinner>
          </template>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button :id="'refire_'+data.item.job_id" variant="outline-primary"
                    :disabled="!data.item.finished"
                    @click="refire(data.item.bcd, data.item.mac)">
            <netvs-icon icon="refire_job"></netvs-icon>
          </b-button>
          <b-tooltip :target="'refire_'+data.item.job_id" triggers="hover"
                     variant="primary" placement="left">
            {{ $t('views.tools.macfinder.search_again') }}
          </b-tooltip>
        </template>
        <template v-slot:cell(started)="data">
          {{ dateutil.format_date(new Date(data.item.started)) }}
        </template>
        <template v-slot:cell(bcd)="data">
          <b-link :to="'/dnsvs/bcds/'+data.item.bcd">{{ data.item.bcd }}</b-link>
        </template>
      </b-table>
    </Loading>
  </div>
</template>
<script>
import MACfinderService from '@/api-services/macfinder.service'
import SearchService from '@/api-services/search.service'
import Typeahead from '@/components/Typeahead'
import Loading from '@/components/Loading'
import dateutil from '../../util/dateutil'

export default {
  name: 'MACfinder',
  components: {Typeahead, Loading},
  computed: {
    dateutil() {
      return dateutil
    },
    res_fields() {
      return [
        {
          key: 'mac',
          label: this.$t('system.mac_address')
        },
        {
          key: 'started',
          label: this.$t('system.time'),
          sortable: true
        },
        {
          key: 'status',
          label: this.$t('system.status')
        },
        {
          key: 'bcd',
          label: this.$t('system.broadcast_domain')
        },
        {
          key: 'vlan',
          label: this.$t('system.vlan_id')
        },
        {
          key: 'actions',
          label: this.$tc('system.action', 2)
        }
      ]
    }
  },
  data() {
    return {
      bcds: [],
      search_bcd: null,
      searching_bcds: false,
      search_mac: null,
      bcd_query: '',
      jobs: null,
      render_jobs: [],
      finished_jobs: [],
      poller: null,
      sortBy: 'started',
      sortDesc: true,
      error_text: undefined
    }
  },
  methods: {
    serializeBCD(i) {
      return i.name
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    },
    findFinalPortFromChain(res) {
      const ref_port = res[4][0]
      for (const p of res[5]) {
        if (ref_port.connection_id_nodes[0] !== p.connection_id_nodes[0]) {
          return p
        }
      }
      return null
    },
    async refresh() {
      const jobs = this.$store.state.macfinder_jobs
      const jq = []
      for (const job of jobs) {
        if (!this.finished_jobs.includes(job)) {
          try {
            jq.push(MACfinderService.pollJob(this.$store.state, job))
          } catch (e) {
            window.console.log('Error on poll. Ignore', e)
          }
        }
      }
      const res = []
      for (const job of jq) {
        try {
          const j = await job
          res.push(j)
        } catch (e) {
          if (e.response.status === 404) {
            // job does not exist
            this.$store.commit('removeMacfinderJob', e.request.responseURL.split('/job/')[1])
          } else {
            window.console.log('Could not await job', e)
          }
        }
      }

      const remainder = []
      for (const j of this.render_jobs) {
        if (this.finished_jobs.includes(j.job_id)) {
          remainder.push(j)
        }
      }
      this.jobs = {}
      for (const r of res) {
        if (r.data.finished) {
          this.finished_jobs.push(r.data.job_id)
          await this.resolveDev(r.data)
        }
        this.jobs[r.data.job_id] = r.data
      }
      this.render_jobs = Object.values(this.jobs)
      this.render_jobs.push(...remainder)
    },
    async fireJob(bcd, mac) {
      let jobs
      try {
        jobs = await MACfinderService.createJob(this.$store.state, bcd, mac)
      } catch (e) {
        if (e.response.status === 404) {
          this.error_text = this.$t('views.tools.macfinder.invalid_bcd')
        } else {
          this.error_text = e.response.data.detail
        }
        return null
      }
      this.error_text = undefined
      for (const j of jobs.data.job_ids) {
        this.$store.commit('newMacfinderJob', j)
      }
      await this.refresh()
    },
    async createJob(ev) {
      ev.preventDefault()
      if (!this.search_bcd) {
        return false
      }
      await this.fireJob(this.search_bcd.name, this.search_mac)
      this.search_mac = this.bcd_query = ''
      return true
    },
    async refire(bcd, mac) {
      await this.fireJob(bcd, mac)
    },
    async getBCDs(bcd) {
      this.searching_bcds = true
      const res = await SearchService.searchBCDRegex(this.$store.state, this.escapeRegExp(bcd), null)
      this.bcds = res.data.bcd_list
      this.searching_bcds = false
    },
    pollData() {
      this.poller = setInterval(() => {
        this.refresh()
      }, 5000)
    },
    async resolveDev(res) {
      for (const r of res.status.result) {
        r._resolved = (await MACfinderService.resolveOutlet(this.$store.state, res.bcd, r.host, r.port)).data.resolved
      }
    }
  },
  watch: {
    bcd_query(new_q) {
      this.getBCDs(new_q)
    }
  },
  beforeDestroy() {
    clearInterval(this.poller)
  },
  async mounted() {
    await this.refresh()
    this.pollData()
  }
}
</script>
